<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>
                <ai_title ai_title="“一言一文”AI发言稿" />
                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_select selectTitle="发言者身份" :select_list="identity_list" @getSelectVal="get_identity" />
                        <ai_select selectTitle="使用场合" :select_list="occasion_list" @getSelectVal="get_occasion" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">主题名称：</p>
                            <div class="upload_box">
                                <el-input v-model="form.theme_name"
                                    placeholder="请录入主题名称。如：庆祝公司本月业绩突破1000万的员工大会"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">要求：</p>
                            <div class="upload_box">
                                <el-input v-model="form.speak_ask" placeholder="请录入内容细节。如：内容鼓舞人心、磅礴大气"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">风格：</p>
                            <div class="upload_box">
                                <el-input v-model="form.speak_style" placeholder="请录入生成语言的风格。如：礼貌亲切"></el-input>
                            </div>
                        </div>
                        <ai_select selectTitle="字数上限" :select_list="num_list" @getSelectVal="get_num" />
                        <button class="create_but" @click="FileData">生成内容</button>
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_select from '@/components/ai_select'
import ai_title from '@/components/ai_title'
import ai_text_content from '@/components/ai_text_content'
export default {
    name: '',
    created() { },
    mounted() { this.getUserInfo() },
    components: {
        ai_title,
        wordNav,
        ai_select,
        ai_text_content
    },
    data() {
        return {
            form: {
                speak_identity: '',
                speak_occasion: '',
                theme_name: '',
                speak_ask: '',
                speak_style: '',
                speak_num: ''
            },
            identity_list: [
                {
                    id: '团队领导',
                    name: '团队领导'
                },
                {
                    id: '部门经理',
                    name: '部门经理'
                },
                {
                    id: '外部嘉宾',
                    name: '外部嘉宾'
                },
                {
                    id: '其他员工',
                    name: '其他员工'
                },
            ],
            occasion_list: [
                {
                    id: '团队会议',
                    name: '团队会议'
                },
                {
                    id: '公司大会',
                    name: '公司大会'
                },
                {
                    id: '外部发布会',
                    name: '外部发布会'
                },
                {
                    id: '其他专题会',
                    name: '其他专题会'
                },
            ],
            num_list: [
                {
                    id: '500',
                    name: '500'
                },
                {
                    id: '1000',
                    name: '1000'
                },
                {
                    id: '1500',
                    name: '1500'
                },
            ],
            fullscreenLoading: false,
            contentData: {}, //内容
            selectList: ['美食', '自然', '专题', '历史', '生活', '宣传', '其他'],
            aiNumShow: false,
            aiPoupShow: false,

        }
    },
    methods: {
        get_identity(val) {
            this.form.speak_identity = val
        },
        get_occasion(val) {
            this.form.speak_occasion = val
        },
        get_num(val) {
            this.form.speak_num = val
        },
        // 获取用户信息
        getUserInfo() {
            this.curlGet('/api/user/info').then((res) => {
                if (res.data.code) {
                    this.$user_info = res.data.data
                    this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                }
            })
        },
        copy() {
            //参数是复制的内容
            //.then()中的函数是复制成功和复制失败的两个回调函数
            this.$copyText(this.contentData.output_content).then(
                (e) => {

                    return this.$message({
                        message: '复制成功',
                        type: 'success',
                        offset: 80,
                    });
                },
                (err) => {
                    return this.$message({
                        message: '复制失败',
                        type: 'warning',
                        offset: 80,
                    });
                }
            );
        },
        FileData() {
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            this.aiNumShow = false
            this.fullscreenLoading = true
            this.curlPost('/file/create_speechdraft', {
                speak_identity: this.form.speak_identity,
                speak_occasion: this.form.speak_occasion,
                theme_name: this.form.theme_name,
                speak_ask: this.form.speak_ask,
                speak_style: this.form.speak_style,
                speak_num: this.form.speak_num,
                mobile: this.$user_info.mobile,
            }).then(res => {
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
                this.fullscreenLoading = false
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>